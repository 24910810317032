<template>
  <div :id="'idea-' + idea.id" class="card h-100">
    <div @click="removeIdea" class="remove-button">
      <i class="fas fa-times"></i>
    </div>
    <router-link :to="'/ideas/edit/' + idea.id" class="edit-button">
      <i class="fas fa-pencil"></i>
    </router-link>

    <div class="card-body pb-1">
      <div class="row align-items-center">
        <div class="col-8">
          <h3 class="card-title mb-2"><input type="text" v-model="idea.title" @change="saveIdea" class="form-control title"></h3>
          <p class="lead mb-0"><input type="text" v-model="idea.lead" @change="saveIdea" class="form-control lead"></p>
          <ul class="list-unstyled mt-3 mb-0">
            <li v-for="tag in idea.searchTags" class="badge badge-pill badge-light mr-1" :key="tag">{{ tag }}</li>
          </ul>
        </div>
        <div class="col-4 text-right">
          <h4 class="mb-0">Scoring: {{ idea.scoring }}</h4>
          <p>Effort: {{ idea.effort }} / Impact: {{ idea.impact }}</p>
        </div>
      </div>
    </div>

    <div @click="toggleIdeaDetails" class="card-header py-1 text-center">
      <i class="fad" :class="showDetails ? 'fa-caret-up' : 'fa-caret-down'"></i>
    </div>

    <div class="card-body details" :style="showDetails ? '' : 'display:none'">
      <div class="row">
        <div class="col-sm-8">
          <div class="form-group">
            <h5>Beschreibung:</h5>
            <textarea v-model="idea.description" rows="2" class="form-control"></textarea>
          </div>

          <div class="form-group">
            <h5>Nutzerproblem:</h5>
            <textarea v-model="idea.userProblem" rows="2" class="form-control"></textarea>
          </div>

          <h5 v-if="findings.length">Verknüpfte Findings:</h5>
          <ul>
            <li v-for="(finding, index) in findings" :key="index">
              <router-link :to="'/findings/' + finding.nodeUrl"></router-link>{{ finding.title }}
            </li>
          </ul>

          <div>
            <h5>Hypothese:</h5>
            <div class="form-row">
              <div class="col-2">
                <label>Wenn: </label>
              </div>
              <div class="col-10">
                <input v-model="idea.hypothesis.ifTerm" class="form-control mb-2" type="text">
              </div>
            </div>
            <div class="form-row">
              <div class="col-2">
                <label>Dann: </label>
              </div>
              <div class="col-10">
                <input v-model="idea.hypothesis.thenTerm" class="form-control mb-2" type="text">
              </div>
            </div>
            <div class="form-row">
              <div class="col-2">
                <label>Weil: </label>
              </div>
              <div class="col-10">
                <input v-model="idea.hypothesis.becauseTerm" class="form-control mb-2" type="text">
              </div>
            </div>
          </div>
        </div>
        <div class="col-sm-4">
          <table class="table table-sm table-striped table-bordered text-left">
            <thead>
            <tr>
              <th colspan="2">Effort</th>
              <th colspan="2">Impact</th>
            </tr>
            </thead>
            <tbody>
            <tr>
              <td><i class="fad fa-laptop-code fa-fw"></i> Frontend</td>
              <td class="px-0 text-center">
                <input type="number" min="1" max="5" name="frontendEffort" v-model="idea.scores.frontendEffort" class="border-0">
              </td>
              <td><i class="fad fa-palette fa-fw"></i> Visual</td>
              <td class="px-0 text-center">
                <input type="number" min="1" max="5" name="visualContrast" v-model="idea.scores.visualContrast" class="border-0">
              </td>
            </tr>
            <tr>
              <td><i class="fad fa-file-code fa-fw"></i> Backend</td>
              <td class="px-0 text-center">
                <input type="number" min="1" max="5" name="backendEffort" v-model="idea.scores.backendEffort" class="border-0">
              </td>
              <td><i class="fad fa-theater-masks fa-fw"></i> Behaviour</td>
              <td class="px-0 text-center">
                <input type="number" min="1" max="5" name="behaviourContrast" v-model="idea.scores.behaviourContrast" class="border-0">
              </td>
            </tr>
            <tr>
              <td><i class="fad fa-file-invoice fa-fw"></i> Concept</td>
              <td class="px-0 text-center">
                <input type="number" min="1" max="5" name="conceptEffort" v-model="idea.scores.conceptEffort" class="border-0">
              </td>
              <td><i class="fad fa-head-side-brain fa-fw"></i> Patterns</td>
              <td class="px-0 text-center">
                <input type="number" min="1" max="5" name="patternsImpact" v-model="idea.scores.patternsImpact" class="border-0">
              </td>
            </tr>
            <tr>
              <td><i class="fad fa-traffic-cone fa-fw"></i> Other</td>
              <td class="px-0 text-center">
                <input type="number" min="1" max="5" name="otherEffort" v-model="idea.scores.otherEffort" class="border-0">
              </td>
              <td><i class="fad fa-users fa-fw"></i> Traffic</td>
              <td class="px-0 text-center">
                <input type="number" min="1" max="5" name="trafficImpact" v-model="idea.scores.trafficImpact" class="border-0">
              </td>
            </tr>
            </tbody>
          </table>

          <div class="text-center">
            <img :src="getImg(node, 'thumb')" alt="" class="img-fluid">
          </div>
        </div>
      </div>

      <hr>

      <div class="row">
        <div class="col-sm-6">
          <h6 class="text-muted">Patterns:</h6>
          <ul class="list-unstyled my-0">
            <li v-for="pattern in idea.pattern" class="badge badge-pill badge-secondary mr-1" :key="pattern">
              {{ pattern }}
            </li>
          </ul>
        </div>
        <div class="col-sm-6">
          <h6 class="text-muted">Levels:</h6>
          <ul class="list-unstyled my-0">
            <li v-for="level in idea.level" class="badge badge-pill badge-secondary mr-1" :key="level">
              {{ level }}
            </li>
          </ul>
        </div>
        <div class="col-sm-6">
          <h6 class="text-muted">Pagetype:</h6>
          <ul class="list-unstyled my-0">
            <li v-for="pagetype in idea.pagetype" class="badge badge-pill badge-secondary mr-1" :key="pagetype">
              {{ pagetype }}
            </li>
          </ul>
        </div>
        <div class="col-sm-6">
          <h6 class="text-muted">Devices:</h6>
          <ul class="list-unstyled my-0">
            <li v-for="device in idea.device" class="badge badge-pill badge-secondary mr-1" :key="device">
              {{ device }}
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Idea',
  components: {},
  directives: {},
  props: {
    idea: Object
  },
  data() {
    return {
      showDetails: false,
      propMapping: {
        backendEffort: "Backend",
        behaviourContrast: "Verhaltenskontrast",
        conceptEffort: "Konzept",
        patternsImpact: "Pattern",
        otherEffort: "Anderes",
        trafficImpact: "Traffic",
      }
    }
  },
  computed: {
    findings() {
      return this.project.findings.filter(finding => this.idea.findingIds.indexOf(finding.id) >= 0);
    },
  },
  watch: {},
  methods: {
    saveIdea() {
      let index = this.project.ideas.findIndex(i => i.id === this.idea.id);
      this.project.ideas.splice(index, 1, this.idea);
      this.$store.dispatch('project/updateProjectByProp', {prop: 'ideas', data: this.project.ideas})
          .then(res => {
            console.debug(res);
          });
    },
    node() {
      return this.project.netNodes.find(n => n.url === this.idea.nodeUrl);
    },
    removeIdea() {
      let index = this.project.ideas.findIndex(i => i.id === this.idea.id);
      if (confirm('Idee wirklich löschen?')) {
        this.project.ideas.splice(index, 1);
        this.$store.dispatch('project/updateProjectByProp', {prop: 'ideas', data: this.project.ideas})
            .then(res => {
              console.debug(res);
            });
      }
    },
    toggleIdeaDetails() {
      this.showDetails = !this.showDetails;
    }
  },
  beforeMount() {
    if (!this.idea.scores) {
      this.idea.scores = {
        frontendEffort: 3,
        visualContrast: 3,
        backendEffort: 3,
        behaviourContrast: 3,
        conceptEffort: 3,
        patternsImpact: 3,
        otherEffort: 3,
        trafficImpact: 3,
      };
    }
    if (!this.idea.hypothesis) {
      this.idea.hypothesis = {
        ifTerm: "",
        thenTerm: "",
        becauseTerm: ""
      }
    }
    if (!this.idea.id) {
      this.idea.id = this.generateId();
    }
  }
}
</script>

<style scoped lang="scss">
.form-control.title {
  font-size: 1.75rem;
  font-weight: 500;
}
.form-control.title {
  font-size: 1.5rem;
}
</style>